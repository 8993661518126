<template>
  <auth-layout>
    <p class="block font-bold mb-4 pr-20">Vraag hier je nieuw wachtwoord aan</p>
    <form class="w-full">
      <div>
        <label class="block text-sm font-bold mb-2" for="email">
          Uw emailadres
        </label>
        <input class="border rounded w-full py-2 px-3 leading-tight text-black bg-gray-100" :class="[error && !email ? 'border-primary' : '']" id="email" type="email" placeholder="Emailadres" v-model="email" required>
        <p v-if="error" class="text-primary text-sm font-semibold">{{ error }}</p>
      </div>
      <div class="rounded-lg text-white px-4 py-3 text-sm cursor-pointer bg-primary w-44 text-center mt-4" @click="forgotPassword()">
        Wachtwoord resetten
      </div>
      <router-link to="/login"><p class="flex-none cursor-pointer pt-4">Login</p></router-link>
    </form>
  </auth-layout>
</template>

<script>
import AuthLayout from '@/components/layouts/Auth'

export default {
  title: 'Wachtwoord vergeten',
  components: {
    AuthLayout
  },
  data () {
    return {
      email: null,
      error: null
    }
  },
  methods: {
    forgotPassword () {
      const email = this.email
      if (!email) return this.setError('Niet alles is juist ingevuld')
      this.$store
        .dispatch('forgotPassword', { email })
        .then(() => this.$router.push('/login'))
        .catch(() => this.setError('Dit emailadres staat niet in onze database'))
    },
    setError (data) {
      this.error = data
    }
  }
}
</script>
